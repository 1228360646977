<template>
  <div class="wrapper">
    <router-view />
    <div style="text-align: center;">
      <span>南通盒木信息科技有限公司 版权所有 © 2019-2022</span>
      <a href="http://beian.miit.gov.cn" target="_blank">苏ICP备20014894号-4</a>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'UserLayout',
  }
</script>

<style scoped>
  .wrapper {
    min-height: 100%;
    background: #f0f2f5 url('~@/assets/user-background.svg') no-repeat 50%;
    text-align: center;
    padding-top: 60px;
    /* padding: 10%; */
    /* height: 100%; */
  }
</style>